import React from "react"
import { AspectRatio, Box, Container, Flex, Heading, Image, LinkBox, LinkOverlay, Text } from "@chakra-ui/react"

import { useRoutes } from "@app/hooks/useRoutes"
import { useImage } from "@app/hooks/useImage"
import { AnalyticProps, withSection } from "@app/hoc/Section"

type Props = GatsbyTypes.SanitySectionImagesTwoColumns & AnalyticProps

const ImagesTwoColumns: React.FC<Props> = ({ columns, innerRef, handleTrackingClick, tag }) => {
  const { urlResolver } = useRoutes()
  const { getGatsbyImage } = useImage()

  return columns?.length ? (
    <Container as="section" maxW="container.4xl" px={{ base: "4", md: "8", lg: "20" }} ref={innerRef}>
      <Flex flexDir={{ base: "column", md: "row" }} gap={{ base: "4", lg: "8" }} pb={{ base: "8", lg: "18" }}>
        {columns.map(col => {
          const image = getGatsbyImage(col?.image)
          const link = urlResolver(col?.link)

          return image && link ? (
            <LinkBox key={col?.title?.toLowerCase()} w={{ base: "100%", md: "50%" }}>
              <AspectRatio ratio={1 / 1} borderRadius="xl" overflow="hidden" mb={{ base: "5", lg: "6" }}>
                <LinkOverlay href={link.url} onClick={handleTrackingClick}>
                  <Image {...image} />
                </LinkOverlay>
              </AspectRatio>

              <Box textAlign="center">
                <Heading as={tag} size="h4" color="typography.headlines900" mb={{ base: "2", lg: "4" }}>
                  <LinkOverlay href={link.url} onClick={handleTrackingClick}>
                    {col?.title}
                  </LinkOverlay>
                </Heading>

                <Text size="textLinkMedium">
                  <LinkOverlay href={link.url} onClick={handleTrackingClick}>
                    {link.title}
                  </LinkOverlay>
                </Text>
              </Box>
            </LinkBox>
          ) : null
        })}
      </Flex>
    </Container>
  ) : null
}

export default React.memo(withSection(ImagesTwoColumns))
